import React from 'react';

const ErrorModal = ({ error, onClose }) => {
    if (!error) return null;

    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            left: 'auto',
            padding: '15px 20px',
            background: '#ffe5e5',
            color: '#b00000',
            border: '1px solid #b00000',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
            maxWidth: '300px',
            zIndex: 9999,
            transition: 'all 0.3s ease',
        }}
        className="error-modal"
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{textAlign:"left"}}>{error}</p>
                <button 
                    onClick={onClose} 
                    style={{
                        marginLeft: '10px',
                        background: 'transparent',
                        border: 'none',
                        fontSize: '18px',
                        cursor: 'pointer',
                        color: '#b00000',
                    }}
                >
                    ✖
                </button>
            </div>
        </div>
    );
};

export default ErrorModal;
