import ContactForm from "../components/contactForm.jsx";
import Menu from "../components/menu"
import Footer from "../components/footer"
import { useEffect, useState } from "react";
import point from "../assets/point.png";

export default function ContactPage(){

    const [tjenester, setTjenester] = useState();
    const [price, setPrice] = useState();

    useEffect(() => {
        const tjenester = JSON.parse(localStorage.getItem("services"));
        if (tjenester) {
            console.log(tjenester)
            setTjenester(tjenester);
        }
        const price = localStorage.getItem("price");
        setPrice(price)
      }, []);

    return(
        <div style={{overflow:"hidden"}}>
        <Menu/>
        <div style={{width:"100%", height:"85px"}}></div>
        <div style={{maxWidth:"600px", margin:"auto"}}>
            <h1 style={{margin:"10px", marginBottom:"0px"}} >Ønsker du å avtale en time? </h1>
            {tjenester ? (
                <h2 style={{margin:"10px", marginBottom:"20px"}}>Oppsumering av tjenester</h2>
            ) : (<></>)}
            {tjenester?.utvendigVask?.map((tjeneste)=>{
                if(tjeneste.chosen){
                    return(
                        <div style={{display:"flex", flexDirection:"row", marginBottom:"15px", paddingInline:"10px"}}>
                            <img style={{width:"23px", height:"23px"}} src={point}></img>
                            <p style={{margin:"0px", marginLeft:"10px"}}>{tjeneste.title}</p>
                        </div>
                    );
                }
            })}
            {tjenester?.polering?.map((tjeneste)=>{
                if(tjeneste.chosen){
                    return(
                        <div style={{display:"flex", flexDirection:"row", marginBottom:"15px", paddingInline:"10px"}}>
                            <img style={{width:"23px", height:"23px"}} src={point}></img>
                            <p style={{margin:"0px", marginLeft:"10px"}}>{tjeneste.title}</p>
                        </div>
                    );
                }
            })}
            {tjenester?.invendigVask?.map((tjeneste)=>{
                if(tjeneste.chosen){
                    return(
                        <div style={{display:"flex", flexDirection:"row", marginBottom:"15px", paddingInline:"10px"}}>
                            <img style={{width:"23px", height:"23px"}} src={point}></img>
                            <p style={{margin:"0px", marginLeft:"10px"}}>{tjeneste.title}</p>
                        </div>
                    );
                }
            })}
            {price && (
                <h2 style={{margin:"10px", marginBottom:"20px"}}>Pris: {price} kr</h2>
            )}
        </div>
        <ContactForm tjenester={tjenester} price={price}/>
        <Footer/>
        </div>
    );
}