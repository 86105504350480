export default function Review ({review, text, name, date, letter, color}) {
    return(
        <div style={{marginBottom:"55px", marginTop:"40px"}}>
            <div style={{width:"297px", height:"244px", backgroundImage:"url('images/review_background.png')", margin:"auto"}}>
                <div style={{display:"flex", flexDirection:"row", marginLeft:"10px"}}>
                    {[...Array(review)].map((_, index) => (
                        <div
                        key={index}
                        style={{
                            backgroundImage: "url('images/star.svg')",
                            width: "30px",
                            height: "27px",
                            backgroundSize: "cover",
                            marginInline:"5px",
                            marginTop:"25px"
                        }}
                        ></div>
                    ))}
                </div>
                <p style={{color:"white", paddingTop:"10px", paddingBottom:"0px", paddingInline:"20px", marginBottom:"0px"}}>{text}</p>
                <a style={{textDecoration:"none", marginLeft:"20px", marginTop:"0px"}} href="https://www.google.com/search?client=safari&sa=X&sca_esv=d715340a68e1457a&rls=en&hl=no-PL&sxsrf=AHTn8zqZWYH2eKimqPQu8NDPECAIss_I6w:1742807450588&q=PDR+Partner+%E2%80%93+Bulkoppretting+%26+Smart+Repair+av+lakkskader+Anmeldelser&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2sbQwNzExNDA1NjI2MjMwNDAx2cDI-IrRPcAlSCEgsagkL7VI4VHDZAWn0pzs_IKCotSSksy8dAU1heBcoKxCUGpBYmaRQmKZQk5idnZxdmIKUL1jXm5qTkpqTnFq0SJWapkEANG0PB-uAAAA&rldimm=4987441053232601044&ved=2ahUKEwj5goTYr6KMAxURB9sEHeovF6IQ9fQKegQIZBAF&biw=2560&bih=1360&dpr=1&ibp=gwp;0,6#vhid=/g/11j2mm5qcp&vssid=rllrl&lkt=LocalPoiReviews"><p style={{color:"lightgrey", paddingInline:"10px", marginLeft:"10px", marginTop:"0px"}}>Les mer</p></a>
            </div>
            <div style={{width:"297px", margin:"auto", display:"flex", flexDirection:"row"}}>
                <div style={{width:"74px", height:"74px", borderRadius: "100%", backgroundColor:`${color}`}}>
                    <p style={{margin:"auto", width:"fit-content", position:"relative", top:"50%", transform:"translate(0,-50%)", fontSize:"40px", fontWeight:"bold", color:"white"}}>{letter}</p>
                </div>
                <div style={{marginLeft:"20px"}}>
                    <p style={{fontSize:"23px", margin:"0px", marginTop:"5px"}}>{name}</p>
                    <p style={{fontSize:"18px", margin:"0px", marginTop:"5px", color:"grey"}}>{date}</p>
                </div>
            </div>
        </div>
    );
}