import {Component} from 'react';
import logo from '../assets/desktop/pdr_logo.png';
import vector1 from '../assets/desktop/Vector1.png';
import call from '../assets/desktop/call.png';
import mail from '../assets/desktop/mail.png';
import location from '../assets/desktop/location_on.png';
import {Link } from "react-router-dom";

class Menu extends Component {
    state  ={ clicked: false};
    handleClick = () => {
        this.setState({clicked:
        !this.state.clicked})
    }
    render() { 
        return(
            <div className='menu-outer'>
                <ul className='menu'>
                    <div className='center-div'>

                    <div className="mobileNav" onClick={this.handleClick}>
                            <i className={
                            this.state.clicked ? "fa-times" : "fa-bars"
                            }></i>
                         </div>

                        <div className='info-big-container'>

                            <div className='info-container'>
                                <img style={{height:"20px", position: "relative", top:"10px", marginRight:"6px"}}  src={location}></img>
                                <p className="p-small">Grini Næringspark 1, 1361 Østerås</p>
                            </div>

                            <div className='info-container'>
                                <img style={{height:"20px", position: "relative", top:"10px", marginRight:"6px"}} src={mail}></img>
                                <p className="p-small">verksted@pdrpartner.no</p>
                            </div>
                            
                            <div className='info-container'>
                                <img style={{height:"20px", position: "relative", top:"10px", marginRight:"6px"}} src={call} ></img>
                                <p className="p-small">(+47) 925 65 764</p>
                            </div>
                        </div>
                    
                        <img className='vector-line' src={vector1}></img>
                    </div>

                        
                        <a style={{width:"fit-content", marginLeft:"50px"}} href='/'><img className='logo_menu' src={logo}></img></a>
                        <div id='navbar' className={this.state.clicked? 
                        "#navbar active" : "#navbar" }>
                        <li>
                            <Link to={'/kontakt'}>
                                <div className='link_menu_right'>Kontakt</div>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/lakkeringsfri-bulkoppretting"}>
                                <div className='link_menu'>Blog</div>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/auto-detailing"}>
                                <div className='link_menu'>Auto Detailing</div>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/"}>
                                <div className='link_menu'>Bulkoppretting</div>
                            </Link>
                        </li>            
                    </div>       
                </ul>
            </div>

        );
    }
}
export default Menu;