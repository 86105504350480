import Menu from "../components/menu"
import Footer from "../components/footer"
import GoogleReviews from "../components/googleReviews";
import useWindowSize from "../components/useWindowWith";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Accordion from "../components/accordion";
import ServiceComponent from "../components/serviceComponent";
import ErrorModal from "../components/errorModal";

export default function AutoDetailingPage(){

    const utvendigVask = [
        {
            title: 'Utvendig Vask',
            chosen: false,
            price: 1250,
            onHandleService: () => {
                toggleChosen("utvendigVask", 0)
            }   
        }
    ];

    const invendigVask = [
        {
          title: 'Enkelt Vask',
          chosen: false,
          price: 2500,
          onHandleService: () => {
            toggleChosen("invendigVask", 0)
        }
        },
        {
          title: 'Dyp Rens',
          chosen: false,
          price: 4500,
          onHandleService: () => {
            toggleChosen("invendigVask", 1)
        }
        },
      ];

      const polering = [
        {
          title: 'Standard polering',
          chosen: false,
          price: 4500,
          onHandleService: () => {
            toggleChosen("polering", 0)
        }
        },
        {
          title: 'Premium polering',
          chosen: false,
          price: 6500,
          onHandleService: () => {
            toggleChosen("polering", 1)
        }
        },
        {
            title: 'Lakkforsegling',
            chosen: false,
            price: 3500,
            onHandleService: () => {
                toggleChosen("polering", 2)
            }
          },
      ];

      const carType = [
        {
            type: "Sedan",
            image: "images/services/sedan.svg",
            price: 1.0,
            chosen: true
        },
        {
            type: "SUV",
            image: "images/services/suv.svg",
            price: 1.2,
            chosen: false
        },
        {
            type: "Van",
            image: "images/services/van.svg",
            price: 1.3,
            chosen: false
        } 
      ]

      const { width, height } = useWindowSize();
      const [price, setPrice] = useState(0);
      const [services, setServices] = useState({
          utvendigVask: utvendigVask, 
          invendigVask: invendigVask,
          polering: polering
      });
      const [carTypes, setCarTypes] = useState(carType);
      const [showPrice, setShowPrice] = useState(false);
      const [showTypes, setShowTypes] = useState(false);
      const [error, setError] = useState("");
      const navigate = useNavigate();

      const toggleCarType = (selectedType) => {

        setCarTypes(prev =>
            prev.map(car => ({
                ...car,
                chosen: car.type === selectedType ? !car.chosen : false
            }))
        );
      }
   
      const toggleChosen = (groupKey, index, showPriceBlock) => {
        if (showPriceBlock === true) {
            setShowPrice(true);
        }

        setServices((prev) => {
          const isCurrentlyChosen = prev[groupKey][index].chosen;
          const toggledChosen = !isCurrentlyChosen;
      
          const updatedGroup = prev[groupKey].map((service, i) =>
            i === index ? { ...service, chosen: toggledChosen } : service 
          );
      
          const updatedServices = {
            ...prev,
            [groupKey]: updatedGroup,
          };

          if (groupKey === 'polering' && toggledChosen && index === 0){
            const updatedPolering = [...prev.polering];

            updatedPolering[0] = {
                ...updatedPolering[0],
                chosen: true
            }

            updatedPolering[1] = {
                ...updatedPolering[1],
                chosen: false
            }
            updatedServices.polering = updatedPolering;
          }

          if (groupKey === 'invendigVask' && toggledChosen && index == 0){
            const updatedVask = [...prev.invendigVask];

            updatedVask[0] = {
                ...updatedVask[0],
                chosen: true
            }

            updatedVask[1] = {
                ...updatedVask[1],
                chosen: false
            }
            
            updatedServices.invendigVask = updatedVask;
          }

          if (groupKey === 'invendigVask' && toggledChosen && index == 1){
            const updatedVask = [...prev.invendigVask];

            updatedVask[1] = {
                ...updatedVask[1],
                chosen: true
            }

            updatedVask[0] = {
                ...updatedVask[0],
                chosen: false
            }
            
            updatedServices.invendigVask = updatedVask;
          }

          if (groupKey === 'polering' && toggledChosen && index === 1){
            const updatedPolering = [...prev.polering];

            updatedPolering[0] = {
                ...updatedPolering[0],
                chosen: false
            }

            updatedPolering[1] = {
                ...updatedPolering[1],
                chosen: true
            }
            updatedServices.polering = updatedPolering;
          }

          if (groupKey === 'polering' && toggledChosen) {
            const updatedUtvendig = [...prev.utvendigVask];
            updatedUtvendig[0] = {
              ...updatedUtvendig[0],
              chosen: true,
            };
            updatedServices.utvendigVask = updatedUtvendig;
          }

          if (groupKey === 'polering' && toggledChosen && index === 2 && !prev.polering[1].chosen){
            const updatedPolering = [...prev.polering];
            updatedPolering[0] = {
                ...updatedPolering[0],
                chosen: true
            }
            updatedPolering[2] = {
                ...updatedPolering[2],
                chosen: true
            }
            updatedServices.polering = updatedPolering;
          }
      
          if (groupKey === 'utvendigVask' && index === 0 && !toggledChosen) {
            const updatedPolering = prev.polering.map((service) => ({
              ...service,
              chosen: false,
            }));
            updatedServices.polering = updatedPolering;
          }
      
          return updatedServices;
        });
      };
      

    function calculateTotalPrice() {
        let total = 0;
      
        Object.values(services).forEach((group) => {
          group.forEach((service) => {
            console.log(service)
            if (service.chosen) {
              total += service.price;
            }
          });
        });

        Object.values(carTypes).forEach((type) => {
            if(type.chosen){
                total *= type.price;
            }
        })

        return total;
      }

    useEffect(() => {
        setPrice(calculateTotalPrice());
      }, [services, carTypes]);

    
    const handleFormSubmission = () => {
        if(validateServices()){
            setError("");
            localStorage.setItem("services", JSON.stringify(services));
            localStorage.setItem("price", price)
            navigate("/kontakt")
        } else {
            console.log(error)
        }
    }

    const validateServices = () => {
        const poleringError = validatePolering();
        if (poleringError) {
            setError(poleringError)
            return false;
        }

        return true;
    }

    const validatePolering = () => {
        const polering = services.polering;

        if ((polering[0].chosen || polering[1].chosen || polering[2].chosen) && !services.utvendigVask[0].chosen) {
            return "Utvendig Vask er boligatorisk for polering tjenester, legg til tjenesten for å bestille timen."
        }

        if (polering[2].chosen && !(polering[0].chosen || polering[1].chosen)) {
            return 'Polering er obligatorisk for Lakkforsegling tjenesten. Legg til "Standard Polering" eller "Premium Polering" for å bestille timen.'
        }
    }

    return(
        <div style={{overflow:"hidden", backgroundColor:"white"}}>
        <Menu/>
        <div style={{height: "75vh", backgroundColor:"lightgrey", backgroundImage: "url('gif/compressed.gif')", backgroundRepeat:"no-repeat", backgroundSize: "cover",
             backgroundPosition: "center",}}>
            <div style={{position:"relative", color:"#eeeeee", marginLeft:"50px", width:"fit-content", fontWeight:"700", fontSize:"26px", top:"50px", textAlign:"left"}}>
            <h1 className="barlow-condensed-bold shiny-text" style={{ marginTop: "0px", marginBottom: "0px" }}>
            Auto Detailing Bærum & Oslo
            </h1>
            </div>
        </div>
        <div style={{paddingTop:"50px", paddingBottom:"20px", backgroundColor:"black"}}>
            <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", color:"white", width:"fit-content", margin:"auto"}}>Et nytt liv for bilen din.</h1>
            <Link to={"/kontakt"}>
                <button style={{backgroundColor:"#FF4C00", borderRadius:"10px", width:"200px", color:"white", position:"relative", left:"50%", transform:"translate(-50%)", marginTop:"30px"}}>
                    Bestill time
                </button>
            </Link>
        </div>
        {width < 760 ? (
            <section style={{backgroundColor:"white"}}>
                <div style={{backgroundColor:"white", paddingTop:"90px", paddingBottom:"70px"}}>
                    <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", width:"fit-content", margin:"auto", fontSize:"35px"}} >Hva driver vi med?</h1>
                    <p style={{maxWidth:"85%", margin:"auto", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>Vi løfter bilpleie til et høyere nivå, der hver minste detalj får den omsorgen den fortjener. Ved hjelp av nøye utvalgte metoder og produkter sikrer vi en finish og beskyttelse som overgår det vanlige. Hos oss får du ikke bare en skinnende bil – du får et håndverk som virkelig gjør at bilen din skiller seg ut.</p>
                </div>
                <img src="images/polering_PDR.png" style={{width:"95%", maxWidth:"500px", position:"relative", top:"5px", left:"50%", transform:"translate(-50%)", marginBottom:"80px"}}>
                </img>
            </section> 
        ):(
            <section style={{backgroundColor:"white", display:"flex", maxWidth:"1600px", margin:"auto"}}>
                <div style={{backgroundColor:"white", paddingTop:"60px", paddingBottom:"70px", width:"50%", textAlign:"left"}}>
                    <div style={{width:"fit-content", margin:"auto"}}>
                    <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", width:"fit-content", fontSize:"35px"}} >Hva driver vi med?</h1>
                    <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>Vi løfter bilpleie til et høyere nivå, der hver minste detalj får den omsorgen den fortjener. Ved hjelp av nøye utvalgte metoder og produkter sikrer vi en finish og beskyttelse som overgår det vanlige. Hos oss får du ikke bare en skinnende bil – du får et håndverk som virkelig gjør at bilen din skiller seg ut.</p>
                    </div>
                </div>
                <div style={{display:"flex", width:"50%"}}>
                <img src="images/polering_PDR.png" style={{width:"60%", maxWidth:"500px", marginInline:"auto", marginBottom:"40px", marginTop:"40px"}}>
                </img>
                </div>
            </section> 
        )}
        {width < 760 ? (
            <section style={{backgroundColor:"lightgrey"}}>
                <div style={{backgroundColor:"lightgrey", paddingTop:"30px", paddingBottom:"30px"}}>
                    <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", width:"fit-content", margin:"auto", fontSize:"35px", }} >Lokalet vårt</h1>
                    <p style={{maxWidth:"85%", margin:"auto", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>Velkommen til et moderne og nyoppusset verksted dedikert til polering og avansert bilpleie. Vi har skapt et innbydende lokale med komfortable fasiliteter, slik at både du og bilen din får en førsteklasses opplevelse. Her legger vi stolthet i hver eneste detalj, og vår ekspertise sikrer at bilen din forlater verkstedet i topp stand.</p>
                </div>
                <img src="images/lokalet.jpeg" style={{width:"95%", maxWidth:"500px", position:"relative", top:"5px", left:"50%", transform:"translate(-50%)", marginBottom:"40px"}}>
                </img>
                <img src="images/lokal.jpeg" style={{width:"95%", maxWidth:"500px", position:"relative", top:"5px", left:"50%", transform:"translate(-50%)", marginBottom:"80px"}}>
                </img>
            </section>
        ):(
            <section style={{backgroundColor:"lightgrey", display:"flex", margin:"auto"}}>
                <div style={{maxWidth:"1600px", margin:"auto", marginTop:"50px", display:"flex"}}>
                    <div style={{width:"50%", display:"flex", flexDirection:"column"}}>
                        <img src="images/lokal.jpeg" style={{width:"60%", marginInline:"auto", marginBottom:"80px", marginTop:"20px"}}>
                        </img>
                    </div>
                    <div style={{backgroundColor:"lightgrey", paddingTop:"30px", paddingBottom:"30px", width:"50%"}}>
                        <div style={{width:"fit-content", margin:"auto"}}>
                        <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", width:"fit-content", fontSize:"35px", }} >Lokalet vårt</h1>
                        <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>Velkommen til et moderne og nyoppusset verksted dedikert til polering og avansert bilpleie. Vi har skapt et innbydende lokale med komfortable fasiliteter, slik at både du og bilen din får en førsteklasses opplevelse. Her legger vi stolthet i hver eneste detalj, og vår ekspertise sikrer at bilen din forlater verkstedet i topp stand.</p>
                        </div>
                    </div>
                </div>
            </section>
        )}
        <GoogleReviews/>
        <ServiceComponent 
            title="Grundig bilvask – steg for steg"
            content="For å sikre en skånsom og effektiv rengjøring av bilen din, følger vi en nøye tilpasset vaskeprosess:"
            points={[
                "Forvask – Påføring av skum og spesialprodukter for å løsne skitt og forurensninger uten å ripe lakken.",
                "Høytrykkspyling – Grundig avspyling for å fjerne løst smuss og veisalt.",
                "Håndvask – Vask med skånsomme mikrofiberhansker og pH-nøytral såpe for en dypere rengjøring.",
                "Felgrens – Spesialprodukter brukes for å fjerne bremsestøv og inngrodd skitt fra felgene.",
                "Dørkarmer og detaljer – Rengjøring av dørkarmer, grill, speil og andre detaljer.",
                "Tørking – Skånsom tørking med mikrofiberhåndklær for å unngå riper.",
                "Vindusvask – Utvendig rengjøring for krystallklare ruter."
            ]}
            content2="Resultatet er en ren og skinnende bil!"
            backgroundColor="lightgrey"
            image="images/services/utvendig-vask.png"
            handleToggle={()=>toggleChosen("utvendigVask", 0, true)}
            chosen={services.utvendigVask[0].chosen}
        />
        <ServiceComponent 
            title="Lett innvendig bilvask – steg for steg"
            content="For å friske opp bilens interiør på en effektiv og skånsom måte, følger vi disse trinnene:"
            points={[
                "Støvsuging – Fjerning av løst smuss og støv fra seter, gulv og bagasjerom.",
                "Rengjøring av interiørflater – Skånsom vask av dashbord, midtkonsoll og dørpaneler med egnede produkter.",
                "Vindusvask – Innvendig vask av rutene for bedre sikt og en frisk følelse.",
                "Vask av gummimatter – Grundig rengjøring av matter for å fjerne skitt og sand."
            ]}
            content2="Perfekt for deg som ønsker et friskt og rent interiør"
            backgroundColor="white" 
            image="images/services/let-innvendig-vask.png"
            reverse={true}
            handleToggle={()=>toggleChosen("invendigVask", 0, true)}
            chosen={services.invendigVask[0].chosen}
        />
        <ServiceComponent 
            title="Grunnleggende rens av bilens interiør – steg for steg"
            content="Denne behandlingen gir bilen din en grundig oppfriskning, med rens av seter, tepper og alle interiørflater for et rent og velpleid utseende."
            points={[
                "Grundig støvsuging – Fjerning av smuss, støv og sand fra seter, tepper, gulv og bagasjerom.",
                "Dyp rens av stoffseter og tepper – Bruk av spesialprodukter for å fjerne flekker og oppfriske materialene.",
                "Rens og pleie av skinnseter – Skånsom rengjøring og påføring av nærende impregnering for å bevare skinnets elastisitet og glans.",
                "Rengjøring og beskyttelse av plast- og gummideler – Vask, fjerning av smuss og påføring av pleiende produkter for å hindre uttørking og falming.",
                "Innvendig vindusvask – Fjerner fett og støv for en klar sikt.",
                "Vask av gummimatter – Rengjøring og beskyttelse av gummimatter for lengre holdbarhet."
            ]}
            content2="En perfekt løsning for deg som ønsker et skinnende rent interiør med beskyttelse som varer!"
            backgroundColor="lightgrey"
            image="images/services/grunnding-innvendig-vask.png"
            handleToggle={()=>toggleChosen("invendigVask", 1, true)}
            chosen={services.invendigVask[1].chosen}
        />
        <ServiceComponent 
            title="Enkel en-trinns polering – fornyet glans på bilen din!"
            content="Vår en-trinns polering gir bilen din en synlig oppfriskning ved å fjerne lette riper, oksidasjon og matte overflater. Vi bruker profesjonelle poleringsmidler fra Meguiar’s for å gjenopprette lakken og gi den en dypere glans."    
            content2="Dette er en perfekt løsning for deg som ønsker et bedre utseende på bilen uten en full flertrinns polering. Etter behandlingen anbefales det å beskytte lakken med voks eller lakkforsegling for et langvarig resultat."
            backgroundColor="white" 
            image="images/services/en-trinn.png"
            reverse={true}
            handleToggle={()=>toggleChosen("polering", 0, true)}
            chosen={services.polering[0].chosen}
        />
        <ServiceComponent 
            title="To-trinns polering – dypere glans og forbedret lakk"
            content="Vår to-trinns polering er en grundigere behandling som fjerner moderat oksidasjon, vaskeriper og mindre defekter i lakken. Først bruker vi en grovere polish for å korrigere overflaten, deretter en finere polish for å jevne ut og gi lakken en dyp glans."    
            content2="Vi benytter profesjonelle produkter fra Meguiar’s for å sikre et optimalt resultat. Etter behandlingen anbefales det å beskytte lakken med voks eller lakkforsegling for økt holdbarhet og beskyttelse."
            content3="Perfekt for deg som ønsker en merkbar forbedring av bilens utseende!"
            backgroundColor="lightgrey" 
            image="images/services/to-trinns-polering.png"
            handleToggle={()=>toggleChosen("polering", 1, true)}
            chosen={services.polering[1].chosen}
        />
        <ServiceComponent 
            title="Lakkforsegling – langvarig beskyttelse og glans"
            content="Lakkforsegling gir bilen din en sterk og varig beskyttelse mot ytre påkjenninger som UV-stråler, sur nedbør, veisalt og skitt. Behandlingen skaper en glatt og smussavvisende overflate, noe som gjør bilen enklere å vaske og holde lakken blank lenger."    
            content2="Vi bruker høykvalitets produkter fra Meguiar’s for å sikre optimal beskyttelse og glans. Lakkforseglingen kan vare i flere måneder, avhengig av type forsegling og vedlikehold."
            content3="Perfekt for deg som ønsker å bevare bilens utseende og gjøre bilpleien enklere!"
            backgroundColor="white" 
            image="images/services/lakkforsegling.png"
            reverse={true}
            handleToggle={()=>toggleChosen("polering", 2, true)}
            chosen={services.polering[2].chosen}
        />
        <section style={{backgroundColor:"lightgrey", padding:"10px", paddingBottom:"100px"}}>
            <h1 style={{margin:"auto", width:"fit-content", maxWidth:"100%", marginTop:"50px", marginBottom:"50px", fontSize:"35px"}}>Pris kalkulator</h1>
            <div
                style={styles.modalContent}
            >
                <div>
                    <h1>Detailing Tjenester</h1>
                    <div style={blockStyle}>
                    <span>{services.utvendigVask[0].title}</span>
                    <button style={{width:"80px", marginBottom:"0px", fontSize:"18px", backgroundColor: services.utvendigVask[0].chosen ? "transparent": "#FF4C00", color: services.utvendigVask[0].chosen ? "black" : "white", borderRadius:"10px", height:"45px", margin:"10px", border: services.utvendigVask[0].chosen ? "1px solid black":"1px solid transparent"}} onClick={services.utvendigVask[0].onHandleService}>{services.utvendigVask[0].chosen ? "Fjern" : "Legg til"}</button>
                    </div>
                    <Accordion title="Innvendig Vask" blocks={services.invendigVask}/>
                    <Accordion title="Polering" blocks={services.polering}/>
                </div>
                <p style={{fontSize:"25px"}}>Velg bilen din</p>
                <section style={{display:"flex", flexDirection: width > 760 ? "row" : "column", justifyContent:"space-between"}}>
                    {carTypes.map((car) => (
                        <div
                            key={car.type}
                            onClick={() => toggleCarType(car.type)}
                            style={{
                                borderRadius: "10px",
                                boxShadow: car.chosen
                                    ? "0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 24px 0 rgba(0, 0, 0, 0.3)"
                                    : "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                border: car.chosen ? "2px solid #FF4C00" : "2px solid transparent",
                                padding: "10px",
                                cursor: "pointer",
                                transition: "all 0.3s",
                                margin:"5px"
                            }}
                        >
                            <img src={car.image} style={{ width: width > 760 ? "200px" : "100%", height: "100px" }} alt={car.type} />
                            <p style={{ margin: 0, marginBottom: "10px", fontWeight: car.chosen ? "bold" : "normal" }}>
                                {car.type}
                            </p>
                        </div>
                    ))}
                </section>
                <div style={{width:"300px", height:"75px", margin:"auto", borderRadius:"5px"}}>
                    <p style={{fontSize:"25px"}}>Total pris: {price}kr</p>
                        <button onClick={() => handleFormSubmission()} style={{backgroundColor:"#FF4C00", borderRadius:"10px", width:"200px", color:"white", position:"relative", marginTop:"30px", marginLeft:"10px"}}>
                                Bestill time
                        </button>
                </div>
                <ErrorModal error={error} onClose={() => setError('')} />
            </div>
        </section>
        {price != 0 && showPrice && (
        <div style={{
            position: "fixed",
            bottom: "50px",
            left: "calc(50% + 50px)",
            transform: "translateX(-50%)",
            width: "200px",
            height: "85px",
            backgroundColor: "#FF4C00",
            borderRadius: "20px",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
        }}>
            <div 
            onClick={() => setShowPrice(false)} 
            style={{
                position: "absolute",
                top: "5px",
                right: "10px",
                cursor: "pointer",
                color: "white",
                fontSize: "18px",
                fontWeight: "bold",
                userSelect: "none"
            }}
            >
            ×
            </div>

            <p style={{ fontSize: "24px", color: "white", margin: 0 }}>{price} kr</p>

            <div style={{ position: "absolute", width: "100px", height:"75px", backgroundColor: "white", right: "205px", borderRadius: "10px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)" }}>
            <button 
                onClick={() => setShowTypes(!showTypes)} 
                style={{ width: "100%", height: "20px", margin: 0, borderBottom: "1px solid lightgrey", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", cursor: "pointer" }}
            >
                <img src="images/services/up-arrow.svg" style={{ width: "18px", position: "relative", bottom: "3px", transform: showTypes && "rotate(180deg)", transition: "transform 0.3s ease" }} />
            </button>
            {showTypes && carTypes && (
                <div style={{ position: "absolute", bottom: "90px", width: "50px", display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
                    {carTypes.map((car) => (
                       <button key={car.type} onClick={() => toggleCarType(car.type)} style={{ width: "100px", backgroundColor: car.chosen ? "#FF4C00" : "white", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", fontSize:"18px", margin:"auto", borderRadius: "5px", cursor: "pointer", border:"none" }}>
                            <img src={car.image} style={{ width:"85px", position: "relative", left: "50%", transform: "translate(-50%)", bottom: "10px" }} />
                            
                       </button> 
                    ))}
                </div>
            )}
            {(() => {
                const chosenCar = carTypes.find(car => car.chosen);
                return (
                    <img 
                        src={chosenCar ? chosenCar.image : "images/services/sedan.svg"} 
                        style={{ width: "85%", position: "relative", left: "50%", transform: "translate(-50%)", bottom: "10px" }} 
                    />
                );
            })()}
            </div>
            

            <button 
            onClick={() => handleFormSubmission()}           
            style={{
            width: "100%",
            height: "35px",
            backgroundColor: "white",
            borderRadius: "10px",
            position: "relative",
            top: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin:0
            }}>
            <p style={{ margin: 0 }}>Bestill time</p>
            </button>
        </div>
        )}

        <Footer/>
        </div>
    );
}

const styles = {
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        paddingBottom:"100px",
        borderRadius: "8px",
        maxWidth: "700px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center", 
        position: "relative",
        margin:"auto"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    navButton: {
        padding: "10px 20px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        margin:"10px"
    },
    closeButton: {
        position: "absolute",
        top: "10px",
        right: "-110px",
        background: "none",
        border: "none",
        fontSize: "20px",
        cursor: "pointer",
    },
};

const blockStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    border: '1px solid #ccc',
    paddingInline: '8px',
  };
