import useWindowSize from "./useWindowWith";
import point from "../assets/point.png";

export default function ServiceComponent({backgroundColor, reverse, title, content, points, content2, content3, image, handleToggle, chosen}) {
    const { width, height } = useWindowSize();


    return(
        <>
        {width < 760 ? (
            <section style={{backgroundColor: backgroundColor}}>
                <div style={{backgroundColor: backgroundColor, paddingTop:"30px", paddingBottom:"30px"}}>
                    <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", marginInline:"20px", width:"fit-content", margin:"auto", fontSize:"38px", }} >{title}</h1>
                    <p style={{maxWidth:"85%", marginInline:"20px", marginTop:"30px", marginBottom:"40px", fontSize:"23px"}}>{content}</p>
                    {points?.map((element, index) => (
                            <div style={{maxWidth:"700px", display:"flex", margin:"20px"}} key={index}>
                                <img style={{width:"23px", height:"23px"}} src={point}></img>
                                <p style={{margin:"0px", marginLeft:"10px", fontSize:"23px"}}>{element}</p>
                            </div>
                        ))}
                    <p style={{maxWidth:"85%", marginInline:"20px", marginTop:"40px", marginBottom:"30px", fontSize:"23px"}}>{content2}</p>
                    <p style={{maxWidth:"85%", marginInline:"20px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>{content3}</p>
                    {chosen ? (
                                <button onClick={handleToggle} style={{backgroundColor:"transparent", color:"black", borderRadius:"10px", width:"200px", border:"2px solid black", position:"relative", marginTop:"30px", marginLeft:"10px"}}>
                                    Fjern
                                </button> 
                            ):(
                                <button onClick={handleToggle} style={{backgroundColor:"#FF4C00", borderRadius:"10px", width:"200px", color:"white", position:"relative", marginTop:"30px", marginLeft:"10px"}}>
                                    Legg til
                                </button>
                    )}
                </div>
                <img src={image} style={{width:"95%", maxWidth:"500px", position:"relative", top:"5px", left:"50%", transform:"translate(-50%)", marginBottom:"80px"}}>
                </img>
            </section>
        ) : reverse == true ? (
            <section style={{backgroundColor: backgroundColor, display:"flex", margin:"auto"}}>
                <div style={{maxWidth:"1600px", margin:"auto", marginTop:"50px", display:"flex"}}>
                    <div style={{width:"50%", display:"flex", flexDirection:"column"}}>
                        <img src={image} style={{width:"60%", marginInline:"auto", marginBottom:"80px", marginTop:"20px"}}>
                        </img>
                    </div>
                    <div style={{backgroundColor: backgroundColor, paddingTop:"30px", paddingBottom:"30px", width:"50%"}}>
                        <div style={{width:"fit-content", margin:"auto"}}>
                            <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", width:"fit-content", fontSize:"35px", }} >{title}</h1>
                            <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>{content}</p>
                                {points?.map((element, index) => (
                                <div style={{maxWidth:"700px", display:"flex", margin:"15px"}} key={index}>
                                    <img style={{width:"23px", height:"23px"}} src={point}></img>
                                    <p style={{margin:"0px", marginLeft:"10px"}}>{element}</p>
                                </div>
                            ))}
                            <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>{content2}</p>
                            <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>{content3}</p>
                            {chosen ? (
                                <button onClick={handleToggle} style={{backgroundColor:"transparent", borderRadius:"10px", width:"200px", color:"black", border:"2px solid black", position:"relative", marginTop:"30px", marginLeft:"10px"}}>
                                    Fjern
                                </button> 
                            ):(
                                <button onClick={handleToggle} style={{backgroundColor:"#FF4C00", borderRadius:"10px", width:"200px", color:"white", position:"relative", marginTop:"30px", marginLeft:"10px"}}>
                                    Legg til
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        ) : (
            <section style={{backgroundColor: backgroundColor, display:"flex", margin:"auto"}}>
            <div style={{maxWidth:"1600px", margin:"auto", marginTop:"50px", display:"flex"}}>
                <div style={{backgroundColor: backgroundColor, paddingTop:"30px", paddingBottom:"30px", width:"50%"}}>
                    <div style={{width:"fit-content", margin:"auto"}}>
                        <h1 className="barlow-condensed-bold" style={{marginTop:"0px", marginBottom:"0px", width:"fit-content", fontSize:"35px", }} >{title}</h1>
                        <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>{content}</p>
                        {points?.map((element, index) => (
                            <div style={{maxWidth:"700px", display:"flex", margin:"15px"}} key={index}>
                                <img style={{width:"23px", height:"23px"}} src={point}></img>
                                <p style={{margin:"0px", marginLeft:"10px"}}>{element}</p>
                            </div>
                        ))}
                        <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>{content2}</p>
                        <p style={{maxWidth:"500px", marginTop:"30px", marginBottom:"30px", fontSize:"23px"}}>{content3}</p>
                        {chosen ? (
                                <button onClick={handleToggle} style={{backgroundColor:"transparent", borderRadius:"10px", width:"200px", color:"black", border:"2px solid black", position:"relative", marginTop:"30px", marginLeft:"10px"}}>
                                    Fjern
                                </button> 
                            ):(
                                <button onClick={handleToggle} style={{backgroundColor:"#FF4C00", borderRadius:"10px", width:"200px", color:"white", position:"relative", marginTop:"30px", marginLeft:"10px"}}>
                                    Legg til
                                </button>
                        )}
                    </div>
                </div>
                <div style={{width:"50%", display:"flex", flexDirection:"column"}}>
                    <img src={image} style={{width:"60%", marginInline:"auto", marginBottom:"80px", marginTop:"20px"}}>
                    </img>
                </div>
            </div>
            </section>
        )}
        </>
    );
}