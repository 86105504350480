import Review from "./review";
import useWindowSize from "./useWindowWith";

export default function GoogleReviews () {

    const { width, height } = useWindowSize();

    return(
        <section style={{backgroundColor:"white", paddingTop:"10px", paddingBottom:"50px", maxWidth:"1600px", margin:"auto"}}>
            <div style={{display:"block", flexDirection:"column", margin:"auto", width:"fit-content", paddingTop:"50px"}}>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", maxWidth:"180px"}} src="/images/google_icon.svg"></img>
                <h2 style={{margin:"auto", fontSize:"30px"}}>Mange fornøyde kunder</h2>
            </div>
            <div style={{display:"flex", position:"relative", width:"fit-content", margin:"auto", marginTop:"20px", marginBottom:"50px"}}>
                <p style={{marginTop:"0px", marginRight:"10px", fontSize:"24px"}}>5.0</p>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{backgroundImage:"url('images/star.svg')", width:"30px", height:"27px"}}></div>
                    <div style={{backgroundImage:"url('images/star.svg')", width:"30px", height:"27px"}}></div>
                    <div style={{backgroundImage:"url('images/star.svg')", width:"30px", height:"27px"}}></div>
                    <div style={{backgroundImage:"url('images/star.svg')", width:"30px", height:"27px"}}></div>
                    <div style={{backgroundImage:"url('images/star.svg')", width:"30px", height:"27px"}}></div>
                </div>
                <p style={{marginTop:"0px", marginLeft:"10px", fontSize:"22px", color:"grey"}}>14 anmeldelser</p>
            </div>
            { width < 760 ? (
                <>
                    <Review review={5} text="Marcin har magiske evner når det kommer til bulkoppretting. Bilen min hadde fått en ganske ... " name="Amad Y." date="18.02.2025" letter="A" color="#4285F4"/>
                    <Review review={5} text="Fin og rask oppretting av en bulk til en rimelig pris uten lakkering! " name="Morten W." date="12.11.2024" letter="M" color="#F4B400"/>
                    <Review review={5} text="Gjorde en plettfri jobb på bulken jeg hadde hatt lenge på bilen. Anbefales 😁 " name="Luke W." date="22.03.2024" letter="L" color="#0F9D58"/>
                </>
            ) : (
                <div style={{display:"flex", margin:"auto", width:"100%", maxWidth:"1500px", justifyContent: "space-evenly"}}>
                    <Review review={5} text="Marcin har magiske evner når det kommer til bulkoppretting. Bilen min hadde fått en ganske ... " name="Amad Y." date="18.02.2025" letter="A" color="#4285F4"/>
                    <Review review={5} text="Fin og rask oppretting av en bulk til en rimelig pris uten lakkering! " name="Morten W." date="12.11.2024" letter="M" color="#F4B400"/>
                    <Review review={5} text="Gjorde en plettfri jobb på bulken jeg hadde hatt lenge på bilen. Anbefales 😁 " name="Luke W." date="22.03.2024" letter="L" color="#0F9D58"/>
                </div>
            )}
        </section>
    );
}