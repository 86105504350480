import React, { useState } from 'react';

function Accordion({ title, blocks }) {
  const [isOpen, setIsOpen] = useState(true);

  const containerStyle = {
    border: '1px solid #ccc',
    marginBottom: '8px',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '8px',
    background: '#f1f1f1',
  };

  const contentStyle = {
    padding: '8px',
  };

  const blockStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    border: '1px solid #ccc',
    paddingInline: '8px',
  };

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div style={containerStyle}>
      <div style={headerStyle} onClick={toggleAccordion}>
        <h3>{title}</h3>
        <div style={{ marginTop: '22px' }}>{isOpen ? '▲' : '▼'}</div>
      </div>

      {isOpen && (
        <div style={contentStyle}>
          {blocks.map((block, index) => (
            <div key={index} style={blockStyle}>
              <span>{block.title}</span>
              <button style={{width:"80px", marginBottom:"0px", fontSize:"18px", backgroundColor: block.chosen ? "transparent": "#FF4C00", color: block.chosen ? "black" : "white", borderRadius:"10px", border: block.chosen ? "1px solid black":"1px solid transparent", height:"45px", margin:"10px" }} onClick={block.onHandleService}>{block.chosen ? "Fjern" : "Legg til"}</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Accordion;
