import {Link } from "react-router-dom";
import React, {useState} from "react";
import point from "../assets/point.png";

const ContactForm = ({onSave, price, tjenester, user={}}) =>{

    const exampleInput = `Hei, \n\nJeg ønsker å bestille time på verkstedet. \n\nJeg ønsker å utføre følgende tjenester:\n${
        [
            ...(tjenester?.utvendigVask || []),
            ...(tjenester?.polering || []),
            ...(tjenester?.invendigVask || [])
        ]
        .filter(tjeneste => tjeneste.chosen)
        .map(tjeneste => `\n - ${tjeneste.title}`)
        .join('')
    }`;
    
    const [userData, setUserData] = useState({
        ...user,
        message: user?.message || ''
    });
    const [errors, setErrors] = useState({});
    const {email, phone, message} = userData;

    React.useEffect(() => {
        if (
            userData.message.trim() === '' && 
            tjenester &&
            (tjenester.utvendigVask?.length || tjenester.polering?.length || tjenester.invendigVask?.length)
        ) {
            const input = `Hei, \n\nJeg ønsker å bestille time på verkstedet. \n\nJeg ønsker å utføre følgende tjenester:\n${
                [
                    ...(tjenester.utvendigVask || []),
                    ...(tjenester.polering || []),
                    ...(tjenester.invendigVask || [])
                ]
                .filter(tjeneste => tjeneste.chosen)
                .map(tjeneste => `\n - ${tjeneste.title}`)
                .join('')
            }`;
    
            setUserData(prev => ({
                ...prev,
                message: input
            }));
        }
    }, [tjenester]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setUserData((prevData) => ({...prevData, [name]: value }));
        setErrors({});
    };

    const sendEmail = async() =>{
        console.log(userData);
        const response = await fetch(
                "https://admin.pdrpartner.no/api/contact",
                {
                    method: 'POST',
                    body: JSON.stringify({email: userData.email, phone: userData.phone, message: userData.message}),
                    headers: { 'Content-Type': 'application/json' }
                }
        );
        let resdata = await response.text();
        console.log(response, resdata);

        window.location.replace('https://pdrpartner.no/takk-for-henvendelsen');

    }    
    return (
        <div style={{paddingTop:"0px"}} className="service-container" id="contact-out">
            <div className="contact-left">
                <h1 className='ernest2' >Ring oss på telefon</h1>
                <a href="tel:+4792565764"><button className='black-button' id='ernest3' style={{marginTop: "20px"}}>Ring oss</button></a>
            </div>
            <div className="contact-right" >
                <div style={{margin:"auto"}} className="form-contact">
                    <h2 className='eller-kontakt' style={{textAlign:"left"}}>  Eller send oss en melding</h2>
                    <h2 style={{textAlign:"left"}} className="contact-label">Email</h2>
                    <input className='input2' id="display" name="email" onChange={handleChange}></input>
                    <h2 style={{textAlign:"left"}} className="contact-label">Tlf. nummer</h2>
                    <input className='input2' id="display" name="phone"  onChange={handleChange}></input>
                    <h2 style={{textAlign:"left"}} className="contact-label">Melding (Valgfri)</h2>
                    <textarea value={message} className='input2' style={{float:"left", height:"200px"}} name="message"  onChange={handleChange}></textarea>
                    <button className='send-information' onClick={sendEmail}>Send</button>
                </div>
                        
                        
            </div>
        </div>
    );
}

export default ContactForm;
